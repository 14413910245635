<template>
  <div class="information">
    <div class="bg">
      <img
        src="@imgs/zpxxbg.png"
        width="261px"
        height="91px"
        alt=""
        class="cpzx"
      />
    </div>
    <div class="infocontent">
      <div class="infobox">
        <div class="infolist">
          <div class="infoitem" v-for="item in newsList" :key="item.id">
            <div class="title">招聘职位：Java招聘要求  6-8K</div>
            <div class="infonr">
              <div class="nrtitle">四川名优世汇药业有限公司</div>
              <div class="nrcontent">
                1、独立完成公司医药电商平台需求分析和软件设计；
2、按照项目计划，按时提交高质量代码，完成开发任务；
3、规范文档的编写、维护； 
4、帮助团队其他成员解决技术问题、及相关技术分享。
              </div>
              <div class="detail" @click="toDetails(item.id)">查看详情</div>
              <div class="timer">发布日期：2021-12-01</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [{ id: 0 },],
    };
  },
  methods:{
    toDetails(id){
      this.$router.push({path:'/recruitdetails',query:{id:id}})
    }
  }
};
</script>

<style lang="scss" scoped>
.information {

  .infocontent {
    width: 100%;
    background: #f1f1f1;
    padding-top: 30px;
    .infobox {
      width: 1194px;
      margin: 0 auto;
      .infolist {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .infoitem {
          width: 370px;
          height: 382px;
          background: #ffffff;
          border-radius: 8px;
          text-align: left;
          padding: 26px 34px;
          box-sizing: border-box;
          margin-bottom: 44px;
          
          .title {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            padding-bottom: 8px;
            border-bottom: 1px solid #e6e6e6;
          }
          .infonr {
            margin-top: 24px;
            .nrtitle {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              margin-bottom: 8px;
            }
            .nrcontent {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666565;
            }
            .detail {
              width: 103px;
              height: 35px;
              background: #fe2a1e;
              border-radius: 5px;
              font-size: 18px;
              line-height: 35px;
              color: #fff;
              text-align: center;
              margin-top: 17px;
               cursor:pointer
            }
            .timer {
              font-size: 20px;
              font-family: DengXian;
              font-weight: 400;
              color: #333333;
              margin-top: 23px;
              border-top: 1px solid #e6e6e6;
              padding: 9px 0;
             
            }
          }
        }
      }
    }
  }
}
</style>